import React from 'react';

import '../styles/Work.css';
// const asaLogo = require("../assets/logos/asa-logo.svg");
import asaLogo from '../assets/logos/asa-logo.svg';
import tipsyLogo from '../assets/logos/tipsy-logo.png';
import paceLogo from '../assets/logos/pace-logo.png';

function Work() {
	return (
		<div id='work' className='grid-section section'>
			<h2 className='section-header'>Work.</h2>
			<div className='grid-items'>
				<a className='developx-card grid-item grid-item-wide' href='https://developx.dev/' target='_blank' rel='noreferrer'>
					<span className='flap'>
						<span className='flap-label'>View website</span>
					</span>
					<h1 className='grid-item-title'>Developx</h1>
					<div className='grid-item-divider'></div>
					<p className='grid-item-text'>
						As a<strong> Co-Founder </strong>and<strong> Developer </strong>at <strong>Developx</strong>, I drive the creation of custom mobile applications for our
						clients.
					</p>
					<div className='developx-apps-row'>
						<a className='grid-item-logo' href='https://play.google.com/store/apps/details?id=com.paze.pace' target='_blank' rel='noreferrer'>
							<img src={paceLogo} alt='app-logo' className='pace-logo'></img>
						</a>
						<a className='grid-item-logo' href='https://apps.apple.com/us/app/tipsy-sg/id6447794559' target='_blank' rel='noreferrer'>
							<img src={tipsyLogo} alt='app-logo' className='tipsy-logo'></img>
						</a>
					</div>
				</a>

				<a className='asa-card grid-item grid-item-tall' href='https://asa.team/' target='_blank' rel='noreferrer'>
					<span className='flap'>
						<span className='flap-label'>View website</span>
					</span>
					<h1 className='grid-item-title black-text'>asa.team</h1>
					<img src={asaLogo} alt='asa-logo' className='grid-item-logo'></img>
					<p className='grid-item-text black-text asa-grid-text'>
						I’m a <strong>Fullstack Developer</strong> at <strong>asa.team</strong>, maintaining and creating new features for our mental wellbeing focused app
					</p>
				</a>

				<a className='foodaround-card grid-item grid-item-tall' href='#work'>
					<div className='grid-item-bg foodaround-bg'></div>
					<span className='flap'>
						<span className='flap-label'>View app</span>
					</span>
					<h1 className='grid-item-title black-text'>FoodAround</h1>
					<div className='grid-item-divider black-text'></div>
					<p className='grid-item-text foodaround-grid-text black-text'>
						As a food lover, I founded a platform that helps people find <strong>home-based businesses</strong> near them.
					</p>
				</a>

				<a className='ura-card grid-item grid-item-wide' href='https://www.linkedin.com/in/ng-jun-sheng-a56a64251/' target='_blank' rel='noreferrer'>
					<div className='grid-item-bg ura-bg'></div>
					<span className='flap'>
						<span className='flap-label'>View experience</span>
					</span>
					<h1 className='grid-item-title '>URA Singapore</h1>
					<div className='grid-item-divider'></div>
					<p className='grid-item-text ura-grid-text '>
						As a <strong>Web Development</strong> intern, i led the UI/UX design for a new government portal with <strong>Figma</strong> and implemented the frontend
						with <strong>React.</strong>
					</p>
				</a>
			</div>
		</div>
	);
}

export default Work;
