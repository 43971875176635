import React, { useState, useEffect } from 'react';

import './styles/App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Skills from './components/Skills';
import Work from './components/Work';
import Projects from './components/Projects';
import SayHi from './components/SayHi';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const App = () => {
	// Your web app's Firebase configuration
	// For Firebase JS SDK v7.20.0 and later, measurementId is optional
	const firebaseConfig = {
		apiKey: 'AIzaSyCRvoIaEOXrKpjT3n_nDECxPLej5OInN94',
		authDomain: 'ngjunsheng-82b4f.firebaseapp.com',
		projectId: 'ngjunsheng-82b4f',
		storageBucket: 'ngjunsheng-82b4f.appspot.com',
		messagingSenderId: '695137379973',
		appId: '1:695137379973:web:2c43271fbd119a7e4fabec',
		measurementId: 'G-RHNGQELNYX',
	};

	// Initialize Firebase
	const app = initializeApp(firebaseConfig);
	const analytics = getAnalytics(app);

	// Track home page visit
	logEvent(analytics, 'homepage_view');

	const [backgroundColor, setBackgroundColor] = useState('#FEFBF5');

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
			const screenHeight = window.innerHeight;
			const scrollThreshold = screenHeight * 0.5;

			if (scrollPosition > scrollThreshold) {
				setBackgroundColor('white');
			} else {
				setBackgroundColor('#FEFBF5');
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className='app' style={{ backgroundColor, minHeight: '100vh', transition: 'background-color 1s ease' }}>
			<Header />
			<Hero />
			<Projects />
			<Skills />
			<Work />
			<SayHi />
		</div>
	);
};

export default App;
