import React from 'react';

import '../styles/Projects.css';
import whatsappRedirectLogo from '../assets/logos/whatsapp-redirect-logo.png';
const univusLogo = require('../assets/logos/univus-logo.png');

function Projects() {
	return (
		<div className='grid-section section'>
			<h2 className='section-header'>Projects.</h2>
			<div className='grid-items'>
				<a className='univus-card grid-item grid-item-wide' href='https://apps.apple.com/us/app/univus/id1508660612' target='_blank' rel='noreferrer'>
					<span className='flap'>
						<span className='flap-label'>View app</span>
					</span>
					<h1 className='grid-item-title'>uNivUS</h1>
					<a className='grid-item-logo' href='https://apps.apple.com/us/app/univus/id1508660612' target='_blank' rel='noreferrer'>
						<img src={univusLogo} alt='univus-logo' className='univus-logo'></img>
					</a>
					<p className='grid-item-text'>
						I worked with the <strong>uNivUS</strong> team as a <strong>Flutter Developer</strong> to add features and mini-apps to the official NUS App.
					</p>
				</a>

				<a
					className='whatsapp-card grid-item grid-item-tall'
					href='https://play.google.com/store/apps/details?id=com.junsheng.whatsappredirect'
					target='_blank'
					rel='noreferrer'
				>
					<span className='flap'>
						<span className='flap-label'>View app</span>
					</span>
					<h1 className='grid-item-title'>Whatsapp Redirect</h1>
					<a className='grid-item-logo' href='https://play.google.com/store/apps/details?id=com.junsheng.whatsappredirect' target='_blank' rel='noreferrer'>
						<img src={whatsappRedirectLogo} alt='app-logo' className='whatsapp-redirect-logo'></img>
					</a>
					<p className='grid-item-text asa-grid-text'>I created an application to let me start Whatsapp conversations without saving a person's contact.</p>
				</a>

				<a className='mal-card grid-item grid-item-tall' href='https://github.com/jsincorporated/my_mal_app' target='_blank' rel='noreferrer'>
					<span className='flap'>
						<span className='flap-label'>View release</span>
					</span>
					<h1 className='grid-item-title '>Unofficial MyAnimeList App</h1>
					<div className='grid-item-divider'></div>
					<p className='grid-item-text foodaround-grid-text '>
						I created an unofficial <strong>MyAnimeList</strong> application to browse and manage your anime/manga library.
					</p>
				</a>
			</div>
		</div>
	);
}

export default Projects;
