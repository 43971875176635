import React from "react";

import "../styles/Skills.css";

// Languages logos
import cssLogo from "../assets/logos/css-logo.png";
import dartLogo from "../assets/logos/dart-logo.svg";
import htmlLogo from "../assets/logos/HTML-logo.svg";
import javaLogo from "../assets/logos/java-logo.png";
import javascriptLogo from "../assets/logos/javascript-logo.png";
import pythonLogo from "../assets/logos/python-logo.png";

// Technologies logos
import expressLogo from "../assets/logos/express-logo.png";
import figmaLogo from "../assets/logos/figma-logo.png";
import firebaseLogo from "../assets/logos/firebase-logo.svg";
import flutterLogo from "../assets/logos/flutter-logo.svg";
import gitLogo from "../assets/logos/git-logo.png";
import mongoDBLogo from "../assets/logos/mongodb-logo.png";
import nodejsLogo from "../assets/logos/nodejs-logo.svg";
import reactLogo from "../assets/logos/react-logo.svg";

function Skills() {
    const logoHeight = 60;

    return (
        <div className="grid-section section">
            <h2 className="section-header">Skills.</h2>
            <div className="grid-items">
                <a className="languages-card grid-item grid-item-tall " href="https://www.linkedin.com/in/ng-jun-sheng-a56a64251/" target="_blank" rel="noreferrer">
                    <span className="flap">
                        <span className="flap-label">View skills</span>
                    </span>
                    <h1 className="grid-item-title black-text">Languages</h1>
                    <div className="grid-item-divider black-text"></div>
                    <div className="logo-grid-languages">
                        <img className="logo" src={htmlLogo} alt="htmllogo" height={logoHeight} />
                        <img className="logo" src={cssLogo} alt="csslogo" height={logoHeight} />
                        <img className="logo" src={javascriptLogo} alt="javascriptLogo" height={logoHeight} />
                        <img className="logo" src={javaLogo} alt="javaLogo" height={logoHeight} />
                        <img className="logo" src={pythonLogo} alt="pythonLogo" height={logoHeight} />
                        <img className="logo" src={dartLogo} alt="dartlogo" height={logoHeight} />
                    </div>
                </a>

                <a className="technologies-card grid-item grid-item-tall" href="https://www.linkedin.com/in/ng-jun-sheng-a56a64251/" target="_blank" rel="noreferrer">
                    <span className="flap">
                        <span className="flap-label">View skills</span>
                    </span>
                    <h1 className="grid-item-title">Technologies</h1>
                    <div className="grid-item-divider"></div>
                    <div className="logo-grid-technologies">
                        <img className="logo" src={reactLogo} alt="reactLogo" height={logoHeight} />
                        <img className="logo" src={mongoDBLogo} alt="mongoDBLogo" height={logoHeight} />
                        <img className="logo" src={nodejsLogo} alt="nodejsLogo" height={logoHeight} />
                        <img className="logo" src={flutterLogo} alt="flutterLogo" height={logoHeight} />
                        <img className="logo" src={expressLogo} alt="expressLogo" height={logoHeight} />
                        <img className="logo" src={firebaseLogo} alt="firebaseLogo" height={logoHeight} />
                        <img className="logo" src={gitLogo} alt="gitLogo" height={logoHeight} />
                        <img className="logo" src={figmaLogo} alt="figmaLogo" height={logoHeight} />
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Skills;
