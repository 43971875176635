import React, { useEffect } from 'react';
import '../styles/Hero.css';

import { ReactComponent as HeroSvg } from '../assets/hero.svg';

function Hero() {
    useEffect(() => {
        const tabContainer = document.querySelector('.hero-tab');

        tabContainer.style.transform = `translateY(-30px)`;

        const tabOutlines = document.querySelectorAll('.tab-outline');

        tabOutlines.forEach((tabOutline, index) => {
            const translateY = (index + 1) * 5;
            const opacity = 1 - index * 0.09;

            tabOutline.style.transform = `translateY(${translateY}px)`;
            tabOutline.style.opacity = opacity;
        });
    }, []);

    return (
        <div className='section hero-section '>
            <div className='hero-tab'>
                <h1 className='hero-name'>
                    <span className='hero-ng'>
                        <span className='hero-name-inner'>Ng</span>
                    </span>
                    <span className='hero-jun'>
                        <span className='hero-name-inner'>Jun</span>
                    </span>
                    <span className='hero-sheng'>
                        <span className='hero-name-inner'>Sheng</span>
                    </span>
                </h1>
                <div className='tab-container'>
                    <HeroSvg className='tab' />
                    <HeroSvg className='tab-outline' />
                    <HeroSvg className='tab-outline' />
                    <HeroSvg className='tab-outline' />
                    <HeroSvg className='tab-outline' />
                    <HeroSvg className='tab-outline' />
                    <HeroSvg className='tab-outline' />
                    <HeroSvg className='tab-outline' />
                    <HeroSvg className='tab-outline' />
                    <HeroSvg className='tab-outline' />
                    <HeroSvg className='tab-outline' />
                    <HeroSvg className='tab-outline' />
                </div>
            </div>
            <h2 className='portfolio-intro-header'>Computer science student, web and mobile app developer.</h2>
            {/* <h1>Hello,</h1>
			<h2>I'm Junsheng</h2> */}
        </div>
    );
}

export default Hero;
