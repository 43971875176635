import React from 'react';

import '../styles/Header.css';
import siteLogo from '../assets/logos/site-logo.png';

function Header() {
	// const [isActiveMenu, setIsActiveMenu] = useState(false);
	// const [expanded, setExpanded] = useState(false);

	// const onMenuClick = () => {
	// 	setIsActiveMenu(!isActiveMenu);
	// 	setExpanded(!expanded);
	// 	console.log(isActiveMenu ? 'menu button-clear menu-active' : 'menu button-clear');
	// };

	return (
		<header className='header'>
			{/* <button className={isActiveMenu ? 'menu button-clear menu-active' : 'menu button-clear'} onClick={onMenuClick}>
                <span className='menu-icon-line menu-icon-line-1'></span>
                <span className='menu-icon-line menu-icon-line-2'></span>
                <span className='menu-icon-line menu-icon-line-3'></span>
            </button>
			<div className={`menu-screen ${expanded ? 'expanded' : ''}`}></div> */}

			<a className='site-logo-container' href='http://ngjunsheng.com'>
				<img className='site-logo' src={siteLogo} alt='site logo' />
			</a>
		</header>
	);
}

export default Header;
