import React from "react";

import "../styles/SayHi.css";
const githubLogo = require("../assets/logos/github-logo.jpg");
const linkedinLogo = require("../assets/logos/linkedin-logo.png");
const self = require("../assets/self.png");

function SayHi() {
    return (
        <div className="contact-card section grid-section">
            <h2 className="section-header">Say hi.</h2>
            <div className="contact-info">
                <a className="grid-item-title grid-item-title-left" href="mailto:hello@ngjunsheng.com">
					hello@ <br /> ngjunsheng.com
                </a>
                <div className="grid-item-divider grid-item-divider-left"></div>
                <p className="grid-item-text grid-item-text-left">
					I love building things and solving real world problems. <br />
                    <a className="grid-item-text-left" href="mailto:hello@ngjunsheng.com">
						Say hi!
                    </a>
                    <br />
                    <br />
					Ng Jun Sheng <br />
					Student and software engineer
                </p>
                <div className="contact-links">
                    <a className="contact-link " href="https://github.com/jsincorporated" target="_blank" rel="noreferrer">
                        <img className="contact-icon github-icon" src={githubLogo} alt="github" />
                    </a>
                    <a className="contact-link " href="https://www.linkedin.com/in/ng-jun-sheng-a56a64251/" target="_blank" rel="noreferrer">
                        <img className="contact-icon" src={linkedinLogo} alt="linkedin" />
                    </a>
                </div>
                <div className="contact-picture">
                    <img className="contact-picture-img" src={self} alt="profile" />
                </div>

                {/* <a className="univus-card grid-item grid-item-wide" href="https://apps.apple.com/us/app/univus/id1508660612" target="_blank" rel="noreferrer">
					<span className="flap">
						<span className="flap-label">View app</span>
					</span>
					<h1 className="grid-item-title">uNivUS</h1>
					<img src={univusLogo} alt="univus-logo" className="grid-item-logo univus-logo"></img>
					<p className="grid-item-text">
						I worked with the <strong>uNivUS</strong> team as a <strong>Flutter Developer</strong> to add features and mini-apps to the official NUS App.
					</p>
				</a> */}
            </div>
        </div>
    );
}

export default SayHi;
